.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 270px;
}

.footer__wave-container {
  position: relative;
  margin: 0;
}

.footer__wave {
  width: 2000px;
  height: 100%;
}

.footer__content {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  right: 0;
}

.footer__text {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-text-light);
  justify-content: start;
}

.footer__link__impressum {
  position: relative;
  z-index: 1;
  color: var(--color-text-light);
}
