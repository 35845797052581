/* VideoAndJourneyStart.css */
.title-page {
  overflow: hidden;
}

.title-page__main {
  width: 100%;
}

.video-loading-indicator {
  /* Adjust the padding-top value to match the aspect ratio of your video */
  padding-top: 26.75%; /* 16:9 aspect ratio */
  padding-bottom: 26.75%;
  background: #000; /* or any other background you want */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.title-page__video {
  width: 100%;
  object-fit: cover;
}

.title-page__imageContainer {
  height: 60vh;
  display: none;
  align-items: center;
  position: relative;
}

.title-page__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.title-page__imageContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.title-page__heroText {
  position: absolute;
  bottom: var(--space-lg);
  left: var(--space-md);
  z-index: 2;
}

.title-page__heroText h1 {
  font-size: 40pt;
}

.title-page__heroText p {
  font-size: var(--text-md);
  font-weight: 500;
  color: var(--color-text-dark);
}

@media screen and (max-width: 576px) {
  .title-page__videoContainer {
    display: none;
  }

  .title-page__imageContainer {
    display: flex;
  }
}

.title-page__wave-container {
  position: relative;
  z-index: -1;
  margin-top: -5px;
}

.title-page__wave-container > img {
  width: auto;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1440px) {
  .title-page__wave-container > img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .title-page__wave-container > img {
    height: 220px;
  }
}

.title-page__journey-begin {
  display: grid;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  padding-top: var(--space-md);
}

.title-page__journey-begin-text {
  font-weight: 600;
  font-size: var(--text-lg);
  color: var(--color-text-heading);
}

.title-page__arrow-svg {
  width: 40px;
  animation: move 1.1s infinite ease-in-out;
  justify-self: center;
  transform: rotate(90deg);
}

@keyframes move {
  0%,
  100% {
    margin-top: var(--space-md);
  }
  50% {
    margin-top: var(--space-lg);
  }
}
