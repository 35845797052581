.ausland {
  background-color: var(--color-accent-1);
  margin: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.ausland__content {
  display: flex;
  align-items: start;
}

.ausland__text {
  color: var(--color-text-white);
}

.ausland__text-heading {
  margin: 0;
  color: var(--color-text-light);
}

.ausland__text-paragraph {
  margin: 0;
}

.ausland__selection {
  padding-top: var(--space-md);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: var(--space-xs);
}

@media screen and (max-width: 768px) {
  .ausland__selection {
    flex-direction: column;
    align-items: center;
  }
}

.ausland__selection > div {
  flex: 1;
}

.ausland__selection-button {
  white-space: nowrap;
  min-width: 200px;
  padding: 10px 20px;
  flex: 1;
  text-align: center;
  box-sizing: border-box;
}

.ausland__wave {
  width: auto;
  height: 100%;
  position: relative;
  background-color: var(--color-background);
  margin: 0;
  padding: 0;
  top: 4px;
}

@media screen and (min-width: 1920px) {
  .ausland__wave {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1050px) {
  .ausland__text {
    margin: 0;
  }
}
