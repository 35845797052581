ul {
  padding: 0;
  margin: 0;
}

.card__personTop {
  display: inline-block;
  max-height: 180px;
  width: 100%;
}

.card__personTop--box {
  width: 100%;
  background: #dae0e5;
  height: 100px;
  border-radius: 7px 7px 0px 0px;
}

.card__personTop--image {
  height: 130px;
  width: 130px;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
  transform: translateY(-78px);
  border: 6px solid white;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.card__divider-person {
  margin: 8px 0px;
}

.card__facts-valueJob {
  font-size: var(--text-s-md);
  max-width: 180px;
}

.card__facts-valueCompany {
  font-size: var(--text-sm);
  margin-top: -10px;
}

.card__personBack--title {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 16px;
}

.card__personDetails--back {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  align-self: center;
}

.card__personDetails--head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card__cv {
  text-align: left;
  font-size: 16px;
  padding-inline-start: 0;
  width: 100%;
  height: 250px;
  position: relative;
}

.card__cv::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
}

.card__cv-items {
  max-height: 250px;
  overflow-y: scroll;
}

.card__cv-items::-webkit-scrollbar {
  width: 0;
}

.card__cv-item {
  padding-bottom: 16px;
  display: flex;
  gap: 16px;
}

.card__cv-year {
  color: #4f4f4f;
  min-width: 70px;
  width: 70px;
}
