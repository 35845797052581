/* Testimonial.css */
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-lg);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-lg);
}

.testimonial__text {
  text-align: center;
}

.testimonial__text-heading {
  color: var(--color-primary-dark);
  padding-bottom: var(--space-md);
  margin: 0;
}

.testimonial__text-title {
  margin: 0;
}

.testimonial__text-title--elite {
  text-transform: uppercase;
}

.testimonial__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--space-md);
}

.testimonial__card {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  flex-basis: calc(50% - var(--space-xl));
  border: var(--color-accent-2) 4px solid;
  padding: var(--space-xs);
}

.testimonial__card-header {
  display: flex;
  align-items: center;
  gap: var(--space-sm-fixed);
}

.testimonial__card-image {
  width: 4rem;
  height: 4rem;
}

.testimonial__card-text {
  margin: 0;
}

.testimonial__card-text--name {
  margin: 0;
}

.testimonial__card-text--position {
  color: var(--color-text);
  opacity: 0.75;
  margin: 0;
}

@media screen and (max-width: 880px) {
  .testimonial__card {
    flex-basis: 100%;
    max-width: 100%;
  }
}
