.abi-fertig {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  align-items: center;
  gap: var(--space-lg);
  flex-wrap: wrap;
}

.abi-fertig__picture,
.abi-fertig__text {
  flex: 1 1 50%;
}

.abi-fertig__text {
  max-width: 400px;
}

.abi-fertig__picture {
  max-width: 566px;
  object-fit: contain;
  min-width: 150px;
  max-width: 400px;
}

.abi-fertig__title {
  padding-bottom: var(--space-xs);
}

.abi-fertig__paragraph {
  color: var(--color-accent-1-light);
  margin: 0;
  text-align: justify;
}
