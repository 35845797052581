/* styles/LanguageSelector.css */

.language-selector {
  position: relative;
  display: inline-block;
}

.language-selector__selected {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.language-selector__options {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  background-color: var(--color-background);
  border: 1px solid #ccc;
  z-index: 1;
  flex-direction: column;
}

.language-selector__option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.language-selector__flag {
  width: 24px;
  height: auto;
}

/* Desktop behavior: show options on hover */
@media (min-width: 769px) {
  .language-selector:hover .language-selector__options {
    display: flex;
  }
}

/* Mobile behavior: show options on click */
@media (max-width: 768px) {
  .language-selector__options {
    display: flex;
    flex-direction: column;
  }
}
