/*https://www.joshwcomeau.com/shadow-palette/*/

:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.06),
    0px 1.2px 1.5px -0.7px hsl(var(--shadow-color) / 0.09),
    0px 2.6px 3.3px -1.5px hsl(var(--shadow-color) / 0.12);
  --shadow-elevation-low-top: 0px -0.4px 0.5px hsl(var(--shadow-color) / 0.24),
    0px -0.6px 0.7px -1.2px hsl(var(--shadow-color) / 0.24),
    0px -1.3px 1.5px -2.5px hsl(var(--shadow-color) / 0.24);
  --shadow-elevation-medium: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.06),
    0px 2.6px 3.3px -0.5px hsl(var(--shadow-color) / 0.09),
    -0.1px 5.8px 7.4px -1px hsl(var(--shadow-color) / 0.11),
    -0.1px 13.2px 16.8px -1.5px hsl(var(--shadow-color) / 0.13);
  --shadow-elevation-high: 0px 0.7px 0.9px hsl(var(--shadow-color) / 0.06),
    -0.1px 5px 6.4px -0.2px hsl(var(--shadow-color) / 0.07),
    -0.1px 9.2px 11.7px -0.4px hsl(var(--shadow-color) / 0.08),
    -0.2px 14.2px 18.1px -0.6px hsl(var(--shadow-color) / 0.09),
    -0.2px 21.2px 27px -0.9px hsl(var(--shadow-color) / 0.1),
    -0.3px 31.4px 40px -1.1px hsl(var(--shadow-color) / 0.11),
    -0.5px 45.9px 58.5px -1.3px hsl(var(--shadow-color) / 0.12),
    -0.7px 65.9px 84px -1.5px hsl(var(--shadow-color) / 0.13);
}
