.button__parent {
  display: contents;
}

.button {
  background-color: var(--btn-primary-bg);
  border: none;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  font-size: var(--text-sm);
  font-weight: 900;
  padding: var(--space-sm) var(--space-lg);
  cursor: pointer;
  color: #fff;
  font-family: 'Inter';
  transition:
    box-shadow 0.2s,
    background-color 0.15s ease;
  margin-top: var(--space-md);
  align-items: center;
}

.button:hover {
  background-color: var(--btn-primary-hover);
}

.button__icon {
  filter: brightness(400%);
  height: 16px;
  padding-left: 8px;
}
