html {
  margin-top: 0;
}
body {
  margin: 0;
  overflow-x: hidden;
  font-family: var(--font-primary), var(--font-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -webkit-hyphens: auto;
  hyphens: auto;
}

#root {
  position: relative;
}

.padding-container {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

@media screen and (max-width: 768px) {
  .padding-container {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }
}

.padding-top {
  padding-top: var(--space-xl);
}

html,
body {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  width: calc(100vw - (100vw - 100%));
  scrollbar-gutter: auto;
}
