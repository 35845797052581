.statistics {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}

@media screen and (max-width: 600px) {
  .statistics {
    flex-direction: column;
  }
}

.statistics__box {
  flex: 1 1 33.3333%; /* grow, shrink, basis */
  border: var(--color-accent-2) 4px solid;
  padding: var(--space-md);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: -2px;
  background-color: var(--color-background);
  box-sizing: border-box;
}

.statistics__number {
  font-weight: 800;
  margin: 0;
  white-space: nowrap;
}

.statistics__description {
  font-weight: 400;
  color: var(--color-accent-1-light);
  margin: 0;
  white-space: wrap;
}
