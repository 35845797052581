.wie-dual__black-shape {
  border-radius: 100% 100% 0% 0% / 25% 25% 0% 0%;
  background-color: var(--black);
  overflow: hidden;
  position: relative;
}

.wie-dual__text {
  padding-top: var(--space-xxl);
  display: flex;
  gap: var(--space-xl);
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: stretch;
}

@media screen and (max-width: 768px) {
  .wie-dual__text {
    flex-direction: column;
    align-items: center;
  }
}

.wie-dual__text-container {
  position: relative;
  background-color: var(--color-background);
  border-radius: 10px;
  padding: var(--space-md);
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--space-xs);
  flex: 1;
}

.wie-dual__text-container h3 {
  margin: 0;
}

.wie-dual__text-container p {
  color: var(--color-text);
  margin: 0;
  text-align: justify;
  flex: 1;
}

.wie-dual__button {
  align-self: flex-start;
}

.wie-dual__bottom-shape {
  width: auto;
  height: 100%;
  margin-bottom: -5px;
}

@media screen and (min-width: 1440px) {
  .wie-dual__bottom-shape {
    width: 100%;
    height: auto;
  }
}
