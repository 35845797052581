/* src/components/style/ButtonControls.css */
.button-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  gap: 8px;
}

.button-controls__btn {
  border: none;
  color: white;
  border-radius: 16px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  flex-basis: 135px;
  gap: 8px;
  transition: 0.2s ease-Out;
  flex-grow: 2;
  height: 55px;
  border: 1.5px solid #ffffff4a;
}

.button-controls__btn--left {
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #ef6d58 0%,
    #d14933 100%
  );
  transition: 0.2s ease-Out;
}

.button-controls__btn--left:hover {
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #e46753 0%,
    #c0422e 100%
  );
}

.button-controls__btn--leftDark {
  background: linear-gradient(151deg, #565758 -28.21%, #414346 100.49%);
}

.button-controls__btn--leftDark:hover {
  background: linear-gradient(151deg, #6e7071 -28.21%, #414346 100.49%);
}

.button-controls__btn--undo {
  background: linear-gradient(151deg, #565758 -28.21%, #414346 100.49%);
  max-width: 55px;

  border-radius: 100%;
  transition: 0.2s ease-Out;
  flex-grow: 1;
}

.button-controls__btn--undo:hover {
  background: linear-gradient(151deg, #6e7071 -28.21%, #414346 100.49%);
}

.button-controls__btn--right {
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #59e756 0%,
    #38bd2c 100%
  );
  transition: 1 ease-Out;
}

.button-controls__btn--right:hover {
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #50d24e 0%,
    #31a726 100%
  );
}

.button-controls__btn--rightDark {
  background: linear-gradient(151deg, #565758 -28.21%, #414346 100.49%);
  flex-direction: row-reverse;
}

.button-controls__btn--rightDark:hover {
  background: linear-gradient(151deg, #6e7071 -28.21%, #414346 100.49%);
}

.button-controls__btn--rightDark img {
  transform: rotate(180deg);
}

.button-controls__btn img {
  width: 24px;
  height: 24px;
  filter: brightness(1000%);
}
