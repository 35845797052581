.topMenu {
  position: relative;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  border-radius: 0px 0px 10px 10px;
  margin-top: -2px;
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 140px;
  padding: 10px 0;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1.5px solid #dae0e5;
  transition: 0.2s ease-out;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.toggleButton:hover {
  background-color: #f0f4f7;
}

.toggleButton__pressed {
  background-color: #f0f4f7;
}

.toggleButton__heart {
  width: 24px;
}

.cardSwitchToggle__button:hover,
.favorites__header:hover {
  color: var(--color-accent-1-dark);
}

.cardSwitchContainer {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9;
}

.cardSwitch {
  display: flex;
  justify-content: space-around;
  background: white;
  border: 1.5px solid #dae0e5;
  padding: 12px 0px;
  border-radius: 10px;
}

.cardSwitch__button {
  display: flex;
  color: white;
  flex-direction: column;
  font-size: var(--text-xs);
  align-items: center;
  border: none;
  padding: 8px 8px;
  width: 100px;
  border-radius: 8px;
  border: 1.5px solid #ffffff6e;
  background: linear-gradient(151deg, #565758 -28.21%, #414346 100.49%);
  font-weight: 600;
  gap: 4px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.cardSwitch__button:hover {
  background: linear-gradient(151deg, #6e7071 -28.21%, #414346 100.49%);
}

.cardSwitch__button img {
  filter: brightness(500%);
}

.topMenu--helpButton {
  cursor: pointer;
}

.favoritesContainer {
  position: absolute;
  right: 0;
  width: 349px;
  height: 402px;
  background-color: #fff;
  border: 1.5px solid #dae0e5;
  border-radius: 10px;
  z-index: 8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.favorites__items::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 72px;
  bottom: 67px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgb(255, 255, 255) 100%
  );
  pointer-events: none;
}

.favorites__items {
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  height: 335px;
}

.favorites__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.favorites__item:last-of-type {
  padding-bottom: 30px;
  border-bottom: none;
}

.favorites__items::-webkit-scrollbar {
  width: 0;
}

.favorites__item-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.favorites__item-image {
  max-height: 40px;
  width: 50px;
  object-fit: contain;
  margin-right: 10px;
}

.favorites__item-name {
  margin: 0;
  font-size: 16px;
}

.favorites__item-description {
  margin: 0;
  font-size: 16px;
  color: #666;
}

.favorites__item-applyLink {
  padding: 4px;
}

.favorites__restartContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow-elevation-low-top);
  align-self: flex-end;
  padding: 16px 0px 16px 0px;
  position: absolute;
  bottom: 0;
  background-color: var(--color-background);
}

.favorites__buttonRestart {
  width: 120px;
  background: #dae0e5;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.favorites__buttonRestart:hover {
  background-color: #cfd4d8;
}

.favorites__empty {
  text-align: center;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #4f4f4f;
}
