/* 

TYPOGRAPHY

*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  /* FONT */
  --font-primary: Inter, sans-serif;
  --font-secondary: Arial, sans-serif;

  /* FONT SIZE */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;

  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-s-md: calc(1em * 0.8 * var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
  --text-xxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );

  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  color: var(--color-text-dark);
}

h1,
h2,
h3,
h4,
form legend {
  margin-bottom: var(--space-xxs);
  line-height: var(--heading-line-height);
}

/* TEXT SIZE */
.text--xxxl {
  font-size: var(--text-xxxl);
}

h1,
.text--xxl {
  font-size: var(--text-xxl);
  margin: 0;
}

h2,
.text--xl {
  font-size: var(--text-xl);
  margin: 0;
}

h3,
.text--lg {
  font-size: var(--text-lg);
  margin: 0;
}

h4,
.text--md {
  font-size: var(--text-md);
  margin: 0;
}

.text--sm,
small {
  font-size: var(--text-sm);
  margin: 0;
}

.text--xs {
  font-size: var(--text-xs);
  margin: 0;
}

p {
  line-height: var(--body-line-height);
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
