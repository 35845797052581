/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.tinbitMain {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  height: calc(100dvh - 85px - env(safe-area-inset-bottom));
  overflow: hidden;
}

.tinbitContainer {
  align-self: center;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.swipe-container {
  position: relative;
  width: 350px;
  height: 400px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-container {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.card {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: var(--shadow-elevation-medium);
  border-radius: 10px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: transform 0.7s;
}

.card-container--flipped .card {
  transform: rotateY(180deg);
}

.card__inner {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card__side {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: white;
}

.card__upperBox {
  position: relative;
  width: 100%;
  max-height: 170px;
}

.swipingStatus {
  position: absolute;
  right: 8px;
  top: 8px;
  background: white;
  padding: 4px 16px;
  border-radius: 20px;
  font-size: var(--text-xs);
  font-weight: 700;
}

.card__side--back {
  transform: rotateY(180deg);
}

.card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
  transition: background-color 0.7s ease;
  z-index: 1;
}

.card__button {
  padding: 10px;
  width: 100%;
  border: none;
  font-size: var(--text-s-md);
  background-color: #dae0e5;
  color: #353942;
  font-weight: 700;
  border-radius: 0px 0px 7px 7px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.card__button:hover {
  background-color: #cfd4d8;
}

.card__apply-link {
  color: #676f7a;
  background-color: #dae0e5;
  padding: 4px 6px;
  text-decoration: none;
  border-radius: 6px;
}

.card__apply-link:hover {
  text-decoration: underline;
}

.card__details--back {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  align-items: flex-start;
  justify-content: space-between;
}

.allSwiped--heading {
  text-align: center;
  max-width: 240px;
}

.allSwiped--text {
  padding-top: 24px;
  text-align: center;
  font-weight: 500;
  max-width: 300px;
  display: inline-block;
  line-height: 32px;
}

.allSwiped--button {
  background: white;
  border: 1.5px solid #dae0e5;
  padding: 4px 10px;
  border-radius: 20px;
  display: inline-block;
  line-height: 20px;
  max-width: 100px;
  cursor: pointer;
  transition: 0.2s ease-Out;
}

.allSwiped--button:hover {
  background-color: #f0f4f7;
}

.allSwiped--button img {
  height: 16px;
  padding-right: 4px;
  margin-bottom: -2px;
}
