/* Block: TheorieAusland */
.TheorieAusland {
}

/* Element: TheorieAusland__title */
.TheorieAusland__title {
  padding-bottom: var(--space-xs);
}

/* Element: TheorieAusland__testimonial-wrapper */
.TheorieAusland__testimonial-wrapper {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;

  border: var(--color-primary) solid 2px;
  border-radius: 15px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .TheorieAusland__testimonial-wrapper {
    flex-direction: column;
  }

  .TheorieAusland__image-wrapper {
    /* height: 30vh; */
  }
}

@media screen and (max-width: 460px) {
  .TheorieAusland__text-header-name,
  .TheorieAusland__text-header-ort {
    font-size: var(--text-sm);
    word-wrap: break-word;
  }

  .TheorieAusland__text-header-name {
    font-size: var(--text-md);
  }
}

.TheorieAusland__image-person {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  object-fit: contain;
}

.TheorieAusland__text-header {
  display: flex;
  gap: var(--space-md);
  flex-direction: row;
  align-items: center;
}

.TheorieAusland__text-header-name {
  margin-top: 0;
}

.TheorieAusland__text-header-ort {
  margin-top: 0;
}

.TheorieAusland__image-wrapper {
  min-width: 50%;
  max-height: 50vh;
  position: relative;
  overflow: hidden;
}

/* Element: TheorieAusland__image--primary */
.TheorieAusland__image-pub {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* position: absolute; */
}

/* Element: TheorieAusland__text */
.TheorieAusland__text {
  padding: var(--space-md);
  align-self: center;
}

/* Element: TheorieAusland__text-content */
.TheorieAusland__text-content {
  color: var(--color-text-dark);
  margin: 0;
  text-align: justify;
}
