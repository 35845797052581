.course-details {
  background-color: var(--color-accent-1);
}

.course-details__wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.module-card {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: 2px solid var(--color-primary);
  overflow: hidden;
  min-height: 120px;
}

@media screen and (max-width: 700px) {
  .module-card {
    flex-direction: column;
  }

  .module-card__description {
    width: revert !important;
  }

  .module-card__header {
    padding: var(--space-sm);
    width: revert !important;
  }
}

.module-card__header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent-2);
  width: 100%;
}

.module-card__header h3 {
  text-align: center;
  margin: 0;
}

.module-card__description {
  padding: var(--space-sm);
  margin: 0;
  color: var(--color-text-light);
  width: 100%;
  align-self: center;
}

.additional-info__title {
  color: var(--color-text-light);
  margin-left: 0;
  margin-top: revert;
}

.additional-info__list {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-sm);
}

.additional-info__item {
  color: var(--color-text-light);
  list-style-type: none;
  background-image: url(./../../general/media/icons/checkmark.svg);
  background-repeat: no-repeat;
  background-position: left top 1px;
  background-size: 16px 16px;
  padding-left: var(--space-lg);
}

.additional-info__link {
  color: inherit;
  text-decoration: underline;
  color: var(--color-primary);
}
