/* 

COLORS

*/

:root {
  /* main colors */
  --color-primary-h: 8; /* Hue */
  --color-primary-s: 83%; /* Saturation */
  --color-primary-l: 64%; /* Lightness */

  --color-primary: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    var(--color-primary-l),
    1
  );
  --color-primary-light: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    calc(var(--color-primary-l) + 5%),
    1
  ); /* Tinted by increasing lightness */
  --color-primary-dark: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    calc(var(--color-primary-l) - 15%),
    1
  ); /* Shaded by decreasing lightness */
  --color-primary-bg: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    var(--color-primary-l),
    0.2
  ); /* Transparency added */

  --color-accent-1-h: 237; /* Hue */
  --color-accent-1-s: 22%; /* Saturation */
  --color-accent-1-l: 20%; /* Lightness */

  --color-accent-1: hsla(
    var(--color-accent-1-h),
    var(--color-accent-1-s),
    var(--color-accent-1-l),
    1
  );
  --color-accent-1-light: hsla(
    var(--color-accent-1-h),
    var(--color-accent-1-s),
    calc(var(--color-accent-1-l) + 15%),
    1
  );
  --color-accent-1-dark: hsla(
    var(--color-accent-1-h),
    var(--color-accent-1-s),
    calc(var(--color-accent-1-l) - 10%),
    1
  );
  --color-accent-1-bg: hsla(
    var(--color-accent-1-h),
    var(--color-accent-1-s),
    var(--color-accent-1-l),
    0.2
  );

  --color-accent-2-h: 21; /* Hue */
  --color-accent-2-s: 68%; /* Saturation */
  --color-accent-2-l: 85%; /* Lightness */

  --color-accent-2: hsla(
    var(--color-accent-2-h),
    var(--color-accent-2-s),
    var(--color-accent-2-l),
    1
  );
  --color-accent-2-light: hsla(
    var(--color-accent-2-h),
    var(--color-accent-2-s),
    calc(var(--color-accent-2-l) + 15%),
    1
  );
  --color-accent-2-dark: hsla(
    var(--color-accent-2-h),
    var(--color-accent-2-s),
    calc(var(--color-accent-2-l) - 10%),
    1
  );
  --color-accent-2-bg: hsla(
    var(--color-accent-2-h),
    var(--color-accent-2-s),
    var(--color-accent-2-l),
    0.2
  );

  --color-background: var(--white);

  /* shades */
  --black: #000000;
  --gray-10: #2e2e31;
  --gray-6: #7b7a7d;
  --gray-4: #a5a5a6;
  --gray-3: #bbbbbc;
  --gray-2: #d1d0d2;
  --gray-1: #e8e7e8;
  --white: white;

  /* typography */
  --color-text-dark: var(--gray-10);
  --color-text-light: var(--gray-1);
  --color-text-heading: var(--black);
  --color-text-white: var(--white);
  --color-text-subtle: var(--gray-6);
  --color-link: var(--color-accent-1);
  --color-link-visited: var(--color-accent-1-dark);
  --color-mark: var(--color-accent-1-bg);
  --color-blockquote-border: var(--gray-2);

  /* buttons */
  --btn-primary-bg: var(--color-primary);
  --btn-primary-hover: var(--color-primary-light);
  --btn-primary-active: var(--color-primary-dark);
  --btn-primary-label: var(--white);

  /* Border */
  --bdr-black: var(--black);
}

@media (prefers-color-scheme: dark) {
  :root {
  }
}

body {
  color: var(--color-body);
}

h1,
h2,
h3,
h4,
form legend {
  color: var(--color-text-heading);
}

a {
  color: var(--color-link);
}
