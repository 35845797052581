/* Block: AuslandPraxis */
.auslandcards__container {
  display: flex;
  align-items: center;
  gap: var(--space-lg);
  position: relative;
}

.auslandcards__header {
  padding-bottom: var(--space-xs);
}

.auslandcards__button {
  background-color: var(--color-primary);
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .auslandcards__button--close {
    display: none;
  }
}

.auslandcards__button--left {
  transform: rotate(180deg);
}

.auslandcards__card-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  flex-grow: 1;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px;
  gap: var(--space-lg);
}

.auslandcards__card {
  flex: 1 1 auto;
  min-width: 250px;
  max-width: 300px;
}

.auslandcards__hidden {
  display: none;
}

.auslandcards__front {
  cursor: pointer;
}

.auslandcards__img {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  object-fit: cover;
}

.auslandcards__city {
  margin-top: var(--space-xs);
}

.auslandcards__name,
.auslandcards__department {
  margin: 0;
}

.auslandcards__department {
  color: var(--color-primary);
}

.auslandcards__back-large {
  position: fixed;
  z-index: 9001;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - (var(--space-xl) * 2));
  max-width: calc(2000px - (var(--space-xl) * 2));
}

@media screen and (max-width: 768px) {
  .auslandcards__back-large {
    width: calc(100% - (var(--space-md) * 2));
  }
}

.auslandcards__large-text {
  position: inherit;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.auslandcards__back-text--large {
  position: relative;
  max-height: 80vh;
  box-shadow: var(--shadow-elevation-medium);
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  text-align: justify;
  border: 2px solid var(--bdr-black);
  border-radius: 20px;
  padding: var(--space-sm);
  z-index: 1;
  background-color: var(--color-background);
  overflow: hidden;
}

.auslandcards__back-text--scrollable {
  scrollbar-width: none;
  overflow-y: auto; /* Changed from 'scroll' to 'auto' */
}

@media screen and (max-width: 1024px) {
  .auslandcards__back-text--scrollable::after {
    content: '';
    position: absolute;
    bottom: var(--space-sm);
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 100%
    );
  }

  .auslandcards__back-text-detail:last-of-type {
    padding-bottom: 40px;
  }
}

.auslandcards__close-button {
  background-color: var(--color-primary);
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  width: 30px;
  height: 30px;
  padding: 0;
}

.auslandcards__back-text-detail {
  margin: 0;
}

.auslandcards__button-img {
  width: 15px;
  height: 15px;
}

.auslandcards__back-text-highlight {
  color: var(--color-primary);
}

.auslandcards__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: 9000; /* Ensure it's below the large box but above other content */
}
