.end-video {
  position: relative;
}

.end-video__wave {
  width: 100%;
}

.end-video__container {
  position: relative;
  margin: 0;
  justify-content: space-between;
  display: flex;
  max-width: 100%;
  gap: var(--space-lg);
  background-color: var(--color-primary);
  margin-top: -4px;
}

.end-video__text {
  align-self: center;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .end-video__container {
    flex-direction: column;
  }

  .end-video__text {
    align-self: start;
  }
}

.end-video__heading {
  padding-bottom: var(--space-xs);
}

.end-video__text p {
  max-width: 400px;
}

.end-video__text a {
  text-decoration: underline;
}

.end-video__video {
  flex: 1;
  background-color: var(--color-primary);
  border-radius: 6px;
  overflow: hidden;
}
