/* companies.css */

.card__apply-link {
  color: #676f7a;
  background-color: #dae0e5;
  padding: 4px 6px;
  text-decoration: none;
  border-radius: 6px;
}

.card__apply-link:hover {
  text-decoration: underline;
}

.card__image {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 7px 7px 0px 0px;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card__logo {
  height: 24px;
  object-fit: contain;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.card__details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.card__company--back {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.card__divider {
  border: 1px solid #dae0e5;
  width: 85%;
}
.card__divider-back {
  width: 100%;
}

.card__company-title {
  font-size: 20px;
  font-weight: 600;
  color: #202531;
}

.card__company-description {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.card__company-description::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
}

.description--text {
  max-height: 180px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding-bottom: 20px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  height: 180px;
}

.description--text::-webkit-scrollbar {
  width: 0;
}

.card__details-text {
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #676f7a;
}

.card__apply {
  display: flex;
  padding: 16px 0px 0px 0px;
  align-items: center;
  text-align: left;
  gap: 16px;
}

.card__apply-text {
  font-weight: 700;
}

.card__details-title {
  margin: 0;
  padding: 0px;
  font-family: 'Rubik';
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
}

.card__facts-text {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
}

.card__facts-icon {
  width: 24px;
  height: 24px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.card__facts {
  display: flex;
  justify-content: space-around;
  margin-bottom: 12px;
  gap: 24px;
}

.card__facts-value {
  font-size: var(--text-s-md);
  max-height: 60px;
}

.card__facts-item {
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
