.nav-sticky {
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 9000;
}

.nav {
  width: 100% auto;
  min-height: 45px;
  max-height: 85px;
  height: 65px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #fff;
  top: 0;
  align-content: center;
  cursor: default;
  position: relative;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0.2;
}

.nav-logo {
  height: 45px;
  width: auto;
}
.logo a:hover {
  color: var(--color-primary);
}

.mainNavDiv {
  flex: content;
  display: flex;
}

.mainNavDiv ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 3;
  padding: 0;
  margin: 0;
  list-style: none;
}

.mainNavDiv ul li {
  list-style: none;
  width: auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.mainNavDiv ul li a::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: var(--color-accent);
  transition: width 0.3s;
}

.mainNavDiv ul li a:hover::after {
  width: 85%;
  background: var(--color-primary);
  margin: auto;
}

.mainNavDiv ul li a {
  text-decoration: none;
  color: var(--color-text-heading);
  font-size: var(--text-md);
  font-weight: 700;
}

.navIcon {
  padding-left: var(--space-xs);
  height: 20px;
  width: 20px;
}

.burgerMenu {
  all: unset;
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .burgerMenu {
    display: block;
  }
  .mainNavDiv {
    position: absolute;
    top: 65px;
    width: 100%;
    left: 0;
  }

  .logo {
    display: inline;
  }

  .mainNavDiv ul {
    flex-direction: column;
    background: var(--color-background);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    gap: var(--space-md);
    padding: var(--space-md) var(--space-lg) var(--space-md) 0;
  }

  .navlinks li {
    align-self: end;
  }

  .show_list .navlinks {
    display: flex;
    align-items: flex-end;
  }

  .mainNavDiv ul li a {
    font-size: var(--text-xxl);
  }

  .menu-open .mainNavDiv {
    display: flex;
  }
}

.burgerMenu {
  cursor: pointer;
  width: 30px;
  height: 25px;
  position: relative;
  margin-right: var(--space-lg);
}

.burgerMenu i {
  background-color: #000;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 4px;
}

.burgerMenu i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.burgerMenu i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.burgerMenu i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.burgerMenu.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.burgerMenu.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.burgerMenu.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

.affixNavbar {
  top: 77px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8999;
  cursor: pointer;
}
