.tinbit-start {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  z-index: 11; /* Ensure it is on top of other elements */
}

.tinbit-start__content {
  max-width: 325px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tinbit-start__heading {
  color: #e15d45;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.tinbit-start__title {
  margin-bottom: 1em;
}

.tinbit-start__description {
  margin-bottom: 1.5em;
  font-weight: 500;
}

.tinbit-start__list {
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 250px;
}

.tinbit-start__list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5em;
  max-width: 250px;
  text-align: left;
  gap: 24px;
  font-weight: 500;
}

.tinbit-start__button {
  border: none;
  color: white;
  border-radius: 16px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  flex-basis: 135px;
  gap: 8px;
  width: 100%;
  transition: 0.2s ease-Out;
  max-height: 55px;
  border: 1.5px solid #ffffff5f;
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #ef6d58 0%,
    #d14933 100%
  );
}

.tinbit-start__button:hover {
  background: radial-gradient(
    92.95% 74.82% at 44.53% 34.38%,
    #e46753 0%,
    #c0422e 100%
  );
}
